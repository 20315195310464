.sales-card {
    margin: 0 auto;
    border: 2px solid #e6f2ff;
    border-radius: 6px;
    padding: 70px 30px 30px 30px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 300px;
    margin: 20px;
    margin-bottom: 50px;
    z-index: 500;
    height: 450px;
    width: 250px;
}

.card-title {
    position: absolute;
    text-align: center;
    background: #ddecfa;
    width: 100%;
    font-weight: bold;
    /* fix this for different views */
    top: 0px;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    text-transform: uppercase;
    border-bottom: 2px solid #e6f2ff;
}

.card-title-dark {
    position: absolute;
    text-align: center;
    background: #a7c7e8;
    width: 100%;
    font-weight: bold;
    /* fix this for different views */
    top: 0px;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    text-transform: uppercase;
    border-bottom: 2px solid #e6f2ff;
}



.card-subtitle {
    font-family: Poppins,sans-serif;
    color: #646464;
    margin: 0;
    padding-top: 20px;
}

.card-price {
    position: relative;
    color: var(--primary-color);
    font-size: 4em;
    font-weight: 400;
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}


.tick-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the start of the flex container */
    gap: 1rem;
    margin-bottom: 20px;
    padding-left: 0; /* Remove default padding */
}
  
.tick-list li {
    display: flex;
    align-items: center; /* Align the tick and the text vertically */
    text-align: left; /* Align text to the left */
}
  
.tick-list li::before {
    content: '';
    display: block;
    margin-right: 0.5rem;
    width: 30px; /* Width of the circle */
    height: 30px; /* Height of the circle */
    background-image: url('../../src/images/blue-tick.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
}