.service-card {
    display: flex;
    /* border: 1px solid #ddd; */
    padding: 16px;
    margin: 8px 0;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: rgb(57, 178, 209, 0.5);
    width: 50vw;
    height: 15vh;
  }
  
  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    margin-right: 50px;
  }
  
  .service-icon {
    max-width: 100px;
    max-height: 100px;
  }
  
  .text-container {
    padding-left: 16px;
    padding-right: 16px;
    margin-left: 50px;
    margin-right: 50px;
  }
  
  .service-text {
    margin: 0;
    font-size: 40px;
    letter-spacing: -1.4px;
    line-height: 32px;
  }

  @media only screen and (max-width: 1100px) {
    .service-text {
        margin: 0;
        font-size: 35px;
        letter-spacing: -1.4px;
        line-height: 32px;
      }
  }

  @media only screen and (max-width: 700px) {
    .service-text {
        margin: 0;
        font-size: 26px;
        letter-spacing: -1.2px;
        line-height: 32px;
      }

      .text-container {
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 25px;
        margin-right: 25px;
      }

      .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 30px;
        margin-right: 30px;
      }
  }

  @media only screen and (max-width: 550px) {
    .service-text {
        margin: 0;
        font-size: 20px;
        letter-spacing: -0.8px;
        line-height: 24px;
      }

      .text-container {
        margin-left: 10px;
        margin-right: 10px;
      }

      .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;
      }
  }
  