.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* Color Palette: Tech Blue and Neutrals */

/* Base Colors */
:root {
  --primary-color: #0a5eac;
  --secondary-color: #23395D;
  /* --light-blue-color:rgb(83, 149, 211); */
  --light-blue-color: #4e9be3;
  --tertiary-color: #F4F4F4;
  --accent-color: #E94E77;
  --background-color: #FFFFFF;
  --text-primary: #333;
  /* Dark text */
  --text-secondary: #555;
  /* Slightly lighter text */
  --text-tertiary: #777;
  /* Even lighter text */
}

body {
  font-family: 'Roboto', sans-serif;
}

/* Background Classes */
.bg-primary {
  background-color: var(--primary-color);
}

.bg-secondary {
  background-color: var(--secondary-color);
}

.bg-tertiary {
  background-color: var(--tertiary-color);
}

.bg-accent {
  background-color: var(--accent-color);
}

.bg-default {
  background-color: var(--background-color);
}

/* Text Color Classes */
.text-primary {
  color: var(--primary-color);
}

.text-secondary {
  color: var(--secondary-color);
}

.text-tertiary {
  color: var(--tertiary-color);
}

.text-accent {
  color: var(--accent-color);
}

.text-default {
  color: var(--background-color);
}

/* Border Classes */
.border-primary {
  border-color: var(--primary-color);
}

.border-secondary {
  border-color: var(--secondary-color);
}

.border-tertiary {
  border-color: var(--tertiary-color);
}

.border-accent {
  border-color: var(--accent-color);
}

/* Button Classes (Optional) */
.btn-primary {
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.btn-primary:hover {
  background-color: var(--secondary-color);
}

.btn-accent {
  background-color: var(--accent-color);
  color: var(--background-color);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.btn-accent:hover {
  background-color: var(--primary-color);
}

/* Homepage css */

/* Existing Color Palette */
/* ... (Your color classes here) ... */

/* General Spacing and Styles */

.homepage {
  /* padding: 2rem 5%; */
  font-family: 'Open Sans', sans-serif;
  background-color: var(--background-color);
  color: var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: auto;
}

.homepage-header {
  /* text-align: center; */
  padding-top: 3rem;
  /* background-image: url('./images/banner.png'); */
  background: linear-gradient(145deg,#000c7c 0,#46d8fd 96%);
  /* background-image: url('./images/background_images/banner-background.jpeg'); */
  background: linear-gradient(145deg, #000c7c7b 0, #46d8fd78 96%)
    url('./images/background_images/banner-background.jpeg');
  background-size: cover;
  background-position: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* Weird issue with background on iOS, need this to fix */
  margin-bottom: -75px;
  /* opacity: 0.8; */
}

.homepage-about,
.homepage-features,
.homepage-testimonials,
.homepage-cta {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-radius: 5px;
  background-color: white;
  /* Card-style background for each section */
  width: 100%;
  /* box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);  */
}

h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  margin-bottom: 1rem;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);P */
  letter-spacing: -1.5px;
  padding-bottom: 5px;
}


h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  margin-bottom: 1rem;
  letter-spacing: -1.5px;
  padding-bottom: 5px;
}

.main-banner {
  padding: 20px;
  /* top,right,bottom,left */
  /* margin: 125px 600px 0px 100px; */
  font-size: 80px;
  letter-spacing: -5px;
  line-height: 80px;
  text-align: left;
  position: flex-start;
  width: 100%;
}


h3,
h4 {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  margin-bottom: 1rem;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
  letter-spacing: -0.5px;
}

p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.homepage-features ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* Two columns for services */
  gap: 1rem;
  /* Space between */
}

.homepage-features li {
  display: flex;
  justify-content: center;
}

.homepage-features li::before {
  content: '';
  /* No text content, we're using a background image */
  display: flex;
  margin-right: 0.5rem;
  width: 50px;
  /* Width of the circle */
  height: 50px;
  /* Height of the circle */
  background-color: none;
  /* Background color for the circle */
  border-radius: 50%;
  /* Makes the background a circle */
  background-image: url('../src/images/check.svg');
  /* Path to your tick image */
  background-repeat: no-repeat;
  background-position: center;
  /* Centers the tick image within the circle */
  background-size: 60%;
  /* Scales the tick image to fit within the circle */
}

.checklist-item {
  width: 30vw;
}

blockquote {
  margin: 2rem 0;
  padding: 1rem;
  background-color: var(--tertiary-color);
  border-left: 3px solid var(--secondary-color);
}

blockquote cite {
  display: block;
  margin-top: 1rem;
  font-size: 0.9rem;
  color: var(--text-tertiary);
}

.homepage-cta {
  text-align: center;
  background-color: var(--primary-color);
  /* Highlight the CTA section */
  color: white;
}

.cta-button {
  position: absolute;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: white;
  color: var(--primary-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 1;
  margin-top: 20px;
  box-shadow: 0px 4px 8px rgba(40, 44, 52, 0.5);
}

.cta-button:hover {
  background-color: var(--secondary-color);
}

.homepage-container {
  margin: 40px;
  z-index: 2;
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
}

.btn-primary {
  margin-top: 1rem;
  display: inline-block;
}

/* footer styles */
.footer {
  background-color: var(--primary-color);
  /* Tech Blue */
  color: var(--secondary-color);
  /* Neutral Light */
  padding: 20px 0;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* General Styles */
.footer {
  background-color: #2C3E50;
  /* Tech Blue */
  color: #ECF0F1;
  /* Neutral Light */
  padding: 40px 60px;
  /* Increased vertical and horizontal padding */
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 3px solid #34495E;
  /* A slightly darker shade for a subtle top border */
}

.footer a {
  color: #ECF0F1;
  /* Neutral Light */
  margin: 0 15px;
  /* Increased side margins for a bit more separation */
  padding: 5px 0;
  /* Added vertical padding for a larger click/tap target */
  text-decoration: none;
  transition: color 0.3s ease;
  border-bottom: 2px solid transparent;
  /* Prepare for a hover effect */
}

.footer a:hover {
  color: #BDC3C7;
  /* Neutral Medium */
  border-bottom: 2px solid #ECF0F1;
  /* Subtle underline effect on hover */
}

.cta-button {
  padding: 15px;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  background-color: white;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}

.page-banner-title {
  padding: 20px;
  font-size: 80px;
  letter-spacing: -5px;
  line-height: 80px;
  width: 100%;
}

.contact-button {
  display: inline-block;
  margin-right: 10px;
  background: var(--light-blue-color);
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  line-height: 38px;
  border-radius: 50px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 170px;
  text-align: center;
  border: none;
  cursor: pointer;
}

.contact-button:hover {
  background: #fff;
  color: #339DFF;
  box-shadow: 0 4px 4px rgba(83, 100, 255, 0.32);
}


/* Mobile responsiveness: Stacking the footer content on smaller screens */
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    text-align: center;
    padding: 30px 20px;
    /* Adjusted padding for mobile devices */
  }

  .footer div {
    margin-bottom: 15px;
    /* Increased margin for vertical separation */
  }

  .page-banner-title {
    padding: 20px;
    font-size: 60px;
    letter-spacing: -3px;
    line-height: 80px;
    width: 100%;
  }
}


.secondary-page {
  /* background-color: var(--tertiary-color); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.longer-text {
  margin-left: 260px;
  margin-right: 260px;
  text-align: left;
}

.longer-text-title {
  margin-left: 260px;
  margin-right: 260px;
  text-align: left;
}

/* scroll to next section button */
.scroll-button {
  background: none;
  border: none;
  position: absolute;
  left: 50%;
  bottom: 12em;
  width: 4em;
  height: 4em;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1;
}

/* The description on the main banner of page*/
.main-description {
  background: none;
  border: none;
  width: auto;
  overflow: hidden;
  z-index: 1;
  width: 80%;
  font-size: 20px;
  padding-left: 40px;
  text-align: left;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  /* padding: 0px 0px 0px 700px; */
}

.background-cover {
  position: absolute;
  z-index: 0;
  bottom: -370px;
  transform: scaleX(-1);
}

.title-container {
  padding: 100px 0px 0px 50px;
}



.cartoon-pic {
  width: 30vw;
  /* border-radius: 10px; */
  /* box-shadow: 2px 2px 10px #282c34; */
  margin: 50px;
}

@media only screen and (max-width: 1200px) {
  .main-banner {
    padding: 15px;
    /* top,left,bottom,right */
    /* margin: 100px 350px 200px 100px; */
    font-size: 70px;
    letter-spacing: -5px;
    line-height: 70px;
    text-align: left;
  }

  .main-description {
    padding: 15px;
    /* top,left,bottom,right */
    /* margin: 100px 350px 200px 100px; */
    font-size: 18px;
    text-align: left;
  }

  .background-cover {
    bottom: -300px;
  }

  .longer-text {
    margin-left: 230px;
    margin-right: 230px;
    text-align: left;
  }

  .longer-text-title {
    margin-left: 230px;
    margin-right: 230px;
    text-align: left;
  }

  .cta-button {
    position: absolute;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    background-color: white;
    color: var(--primary-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    left: 20%;
  }
}

.three-card-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.three-card-holder-sales {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.main-offering {
  transform: scale(1.1);
  z-index: 1;
}

.geometric-deer {
  position: absolute;
  left: 0%;
  top: 10%;
  height: 700px;
  z-index: 5;
  padding-bottom: 100px;
}

.deer-container {
  position: relative;
  right: 30px;
  overflow: hidden;
  /* This will hide any part of the image that overflows this container */
  height: 1000px;
  /* Set this to the height you want to show on the page */
  width: 1500px;
  /* This can be whatever width you want */
}

.mobile-deer-container {
  display: none;
  position: relative;
  right: 0px;
  overflow: hidden;
  /* This will hide any part of the image that overflows this container */
  height: 1000px;
  /* Set this to the height you want to show on the page */
  width: 1500px;
  /* This can be whatever width you want */
}

.page-banner {
  width: 100%;
  height: 50vh;
  /* background-image: url('./images/banner.png'); */
  background-image: url('./images/background_images/banner-background.webp');
  opacity: 0.9;
  /* background-position-x: 500;
    background-position-y: -400; */
  background-position: center -20px;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.intro-section {
  display: flex;
  flex-direction: column;
  width: 50vw;
}

.deal-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* This will vertically center the items */
  justify-content: center;
  gap: 20px;
  /* This adds some space between the image and the text */
  margin-left: 20px;
  margin-right: 20px;
}

.deal-image img {
  /* widt */
  width: 45vw;
  max-width: 500px;
  /* Adjust the max-width as needed */
  height: auto;
  /* This will maintain the aspect ratio of the image */
  margin-left: 30px;
  margin-right: 30px;
}

.pricing-highlight {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

.pricing-highlight .primary-color {
  color: var(--primary-color);
  font-weight: bold;
}


@media only screen and (max-width: 1050px) {

  /* .geometric-deer {
    position: absolute;
    left: 50%;
    top: 20%;
    height: 600px;
    z-index: 5;
    padding-bottom: 100px;
  } */
  .three-card-holder-sales {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-offering {
    order: -1;
    /* This will ensure it's the first item */
    transform: scale(1);
    /* Reset scaling if needed */
  }
}

@media only screen and (max-width: 1150px) {
  /* .geometric-deer {
    position: absolute;
    left: 50%;
    top: 30%;
    height: 600px;
    z-index: 5;
    padding-bottom: 100px;
    overflow: hidden;
  } */
}

@media only screen and (max-width: 1000px) {
  .longer-text {
    margin-left: 180px;
    margin-right: 180px;
    text-align: left;
  }

  .scroll-button .main-description {
    bottom: 6em;
  }


  .background-cover {
    bottom: -200px;
  }

  .longer-text-title {
    margin-left: 180px;
    margin-right: 180px;
    text-align: left;
  }

  .three-card-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 800px) {
  .main-banner {
    padding: 10px;
    /* top,left,bottom,right */
    font-size: 60px;
    letter-spacing: -3px;
    line-height: 65px;
  }

  .background-cover {
    bottom: -120px;
  }

  .main-description {
    padding: 10px;
    /* top,left,bottom,right */
    /* margin: 100px 350px 200px 100px; */
    font-size: 16px;
    text-align: left;
  }

  .longer-text {
    margin-left: 150px;
    margin-right: 150px;
    text-align: left;
  }

  .longer-text-title {
    margin-left: 150px;
    margin-right: 150px;
    text-align: left;
  }

  .deer-container {
    position: relative;
    right: 0px;
    overflow: hidden;
    /* This will hide any part of the image that overflows this container */
    height: 1000px;
    /* Set this to the height you want to show on the page */
    width: 1500px;
    /* This can be whatever width you want */
  }

  .geometric-deer {
    position: absolute;
    left: -10%;
    top: 25%;
    height: 600px;
    z-index: 5;
    padding-bottom: 100px;
    width: 150%;
    height: auto;
  }

  .deal-container {
    flex-direction: column;
    gap: 20px;
    /* This adds some space between the image and the text */
    margin-left: 20px;
    margin-right: 20px;
  }

  .pricing-highlight {
    order: -1;
  }
}

@media only screen and (max-width: 700px) {
  .longer-text {
    margin-left: 80px;
    margin-right: 80px;
    text-align: left;
  }

  .longer-text-title {
    margin-left: 80px;
    margin-right: 80px;
    text-align: left;
  }

  .homepage-features ul {
    display: grid;
    grid-template-columns: 1fr;
    /* Two columns for services */
  }

  .checklist-item {
    width: 65vw;
  }

  .geometric-deer {
    width: 200%;
  }

  .scroll-button {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  /* .longer-text {
    margin-left: 0px; 
    margin-right: 0px;
    text-align: left;
  }
  
  .longer-text-title {
    margin-left: 0px; 
    margin-right: 0px;
    text-align: left;
  }

  .deer-container {
    position: absolute;
    right: 0px;
    top: 0px;
    overflow: hidden;
    height: 900px;
    width: 300px;
  }

  .geometric-deer {
    position: absolute;
    left: -20%;
    top: 55%;
    height: 600px;
    z-index: 1;
    padding-bottom: 100px;
    width: 200%;
    height: auto;
  } */
}

@media only screen and (max-width: 500px) {
  .scroll-button {
    display: none;
  }

  .cta-button {
    left: 50%;
  }

  .main-banner {
    padding: 10px;
    /* top,left,bottom,right */
    font-size: 40px;
    letter-spacing: -2.5px;
    line-height: 45px;
    width: 90%;
  }

  .main-description {
    padding: 10px;
    /* top,left,bottom,right */
    /* margin: 100px 350px 200px 100px; */
    font-size: 16px;
    text-align: left;
    width: 90%;
  }

  .mobile-deer-container {
    padding-top: 350px;
    display: block;
    position: absolute;
    top: 100px;
    /* overflow: hidden; */
    height: 600px;
    /* Set this to the height you want to show on the page */
    width: 90vw;
  }

  .mobile-geometric-deer {
    position: absolute;
    left: -5%;
    top: 37.5%;
    padding-bottom: 100px;
    overflow: hidden;
    width: 70%;
    height: auto;
    z-index: 5;
  }

  .deer-container {
    display: none;
    width: 400px;
    /* This can be whatever width you want */
  }

  .geometric-deer {
    position: absolute;
    left: 22.5%;
    top: 50%;
    padding-bottom: 100px;
    overflow: hidden;
    width: 100%;
    height: auto;
  }

  .background-cover {
    bottom: 0px;
  }

  .homepage-header {
    height: 85vh;
  }
}


@media only screen and (max-width: 400px) {
  .mobile-geometric-deer {
    position: absolute;
    left: -5%;
    top: 39%;
  }
}

@media only screen and (max-height: 750px) {
  .geometric-deer {
    display: none;
  }

  .mobile-geometric-deer {
    display: none;
  }
  
}