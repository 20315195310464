.card {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    text-align: center;
    width: 300px;
    height: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-image {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
}

.info-card-title {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 20px;
}

.card-description {
    margin-top: 10px;
    font-size: 16px;
}

.card-container {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 1150px) {
    .card {
        width: 280px;
        height: 280px;
    }
}

@media only screen and (max-width: 1000px) {
    .card {
        width: 300px;
        height: 300px;
    }
}